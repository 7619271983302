
import http from '@/services/api';
import { mountUrlApiTransparencia } from '@/utils/mountURL';

export function sendGetDocumentos(query = ''): Promise<any> {
  let url = mountUrlApiTransparencia('/documento?_orderBy=datacadastro&_order=desc');
  url = (query != '') ? url +'&'+ query : url;
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export async function sendGetDocumentosById(id: any) {
  const url = mountUrlApiTransparencia(`/documento/${id}`);
  return await http.get(url);
    // .then( (response) => {
    //   return response;
    // })
    // .catch( (error) => {
    //   console.log(error);
    //   return error;
    // });
}

export function sendGetDocAnexosById(id: any): Promise<any> {
  const url = mountUrlApiTransparencia(`/documento/${id}/anexos?_orderBy=data_publicacao&_order=desc`);
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function getAllMapTiposDoc(){
  const mapTiposDoc = [
    { id: '2', nome: 'Execução orçamentária'},
    { id: '9', nome: 'Despesas gerais'},
    { id: '11', nome: 'Relatório de Pessoal'},
    { id: '18', nome: 'Concurso e Seleções'},
    { id: '19', nome: 'Prestação de Contas'},
    { id: '20', nome: 'Documentos necessários para cadastro de empresas'},
    { id: '21', nome: 'Requerimento Anuência Prévia Municipal'},
    { id: '22', nome: 'Plano Municipal de Saúde'},
    { id: '23', nome: 'Licenciamento Ambiental'},
    { id: '24', nome: 'Audiências Públicas'},
    { id: '25', nome: 'Parcerias entre Administração Publica e as Organizações da Sociedade Civil'},
    { id: '26', nome: 'Mural Eletrônico'},
    { id: '27', nome: 'CACS FUNDEB'},
    { id: '28', nome: 'Relatório da LRF - Lei de Responsabilidade Fiscal'},
    { id: '29', nome: 'Convênios e Repasses Públicos'},
    { id: '30', nome: 'Deliberações do Conselho Municipal de Assistência Social - CMAS'},
    { id: '31', nome: 'Conselho Municipal do Idoso - CMI'},
    { id: '32', nome: 'Conselho Municipal de Defesa dos Direitos da Criança e do Adolescente - COMDECA'},
  ]
  return mapTiposDoc;
}

export function getCategoriaTitle(id: any){
  const res: any = getAllMapTiposDoc().find( (item: any) => {
    return item.id == id
  })
  if( res == undefined ){
    return ''
  }
  return res.nome;
}


export default{
  getAllMapTiposDoc,
  getCategoriaTitle,
  sendGetDocumentos,
  sendGetDocumentosById,
  sendGetDocAnexosById
}