import { Component, Vue, Prop } from 'vue-property-decorator'
import documentos from '@/modules/documentos/services/documentos'
import utils from '@/utils';
import ShowPath from '@/components/showPath/showPath.vue';
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    ShowPath,
    Icons
  }
})
export default class ItemDocumento extends Vue {
  @Prop({required: true}) id!: string;

  isLoading = false;
  categoriaTitle = '';
  item: any = {};
  anexos = []

  mapExtraFieldsLabels: any = {
    contrato: 'Número/contrato de repasse',
    ano: 'Ano',
    vigencia: 'Período de vigência',
    convenente: 'Convenente',
    proponente: 'Proponente',
    situacao: 'Situação',
    valor: 'Valor',
    dataPrimeiroRepasse: 'Data do primeiro repasse',
    objeto: 'Objeto',
  }

  ajustDate = utils.date.strDatetimeUSAToBR

  mounted() {
    this.getById(this.getRouteId());
  }

  getRouteId(){
    return this.id;
  }

  isExtraFields(){
    return this.item.fkdocumentotipo == '29';
  }

  getExtraFieldsLabel(position: any){
    if(this.mapExtraFieldsLabels[position] != undefined){
      return this.mapExtraFieldsLabels[position];
    }
    return ''
  }

  async getById(id: any){
    this.isLoading = true;
    try {
      const response = await documentos.sendGetDocumentosById(id);
      this.item = response.data;
      if(this.isExtraFields()){
        this.item.extrafields = JSON.parse(this.item.extrafields);
      }
      this.categoriaTitle = documentos.getCategoriaTitle(this.item.fkdocumentotipo);
      this.getAnexos(id);
    } catch (error: any) {
      if(error.response.data.errors[0].code == 'ID_NOT_FOUND'){
        this.$router.replace('/pagina-nao-encontrada')
      }
    }
    this.isLoading = false;
  }

  getAnexos(id: any){
    documentos.sendGetDocAnexosById(id)
      .then( (response) => {
        this.anexos = response.data.list;
      });
  }

  getUrlAnexo(file: any){
    let arr = file.arquivo;
    arr = arr.split('/')
    // console.log(arr);
    if( arr[2] == 'apitransparencia2.laranjadaterra.es.gov.br' ){
      let local = file.arquivo;
      local = local.replace('./', '/');
      return 'https://apitransparencia2.laranjadaterra.es.gov.br'+local;
    }
    if( arr[2] == 'devapitransparencia.laranjadaterra.es.gov.br' ){
      let local = file.arquivo;
      local = local.replace('./', '/');
      return 'https://devapitransparencia.laranjadaterra.es.gov.br'+local;
    }
    if( arr[1] == 'uploads' &&  arr[2] == 'licitacao' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    if( arr[1] == 'novosUploads' &&  arr[2] == 'licitacoes' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
  }

}
  